const interestSelectOptions = [
  {
    id: 0,
    text: '',
  },
  {
    id: 1,
    text: 'Software Development Outsourcing',
  },
  {
    id: 2,
    text: 'Team Augmentation',
  },
  {
    id: 3,
    text: 'Tech Consultancy',
  },
  {
    id: 4,
    text: 'Staffing',
  },
  {
    id: 5,
    text: 'Referral Program',
  },
  {
    id: 6,
    text: 'Other',
  },
];

export default interestSelectOptions;
