import styled from 'styled-components';
import Box from 'atomic-design/atoms/box';

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.largest};
  transition: ${({ theme }) => theme.transition};
  overflow: hidden;
  cursor: pointer;

  &:hover {
    transition: ${({ theme }) => theme.transition};
    background-color: ${({ theme }) => theme.colors.inputForm};
    border-radius: ${({ theme }) => theme.radius.main};

    svg {
      transform: translateY(5px);
      transition: ${({ theme }) => theme.transition};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.large};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.large};

    &:hover {
      transition: none;
      background-color: ${({ theme }) => theme.colors.white};

      svg {
        transform: translateY(0);
      }
    }
  }
`;

export const TextContainer = styled.div``;

export const Title = styled.h4`
  color: ${({ theme }) => theme.fonts.colors.primary};
  font-size: ${({ theme }) => `${theme.fonts.sizes.headings.heading5}`};
  margin: 0;

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    font-size: ${({ theme }) => `${theme.fonts.sizes.headings.heading6}`};
  }
`;

export const Action = styled.h5`
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};
  padding-top: ${({ theme }) => theme.spacing.medium};
  font-size: ${({ theme }) => `${theme.fonts.sizes.headings.heading6}`};

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    font-size: ${({ theme }) => `${theme.spacing.spacing20}`};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding-top: ${({ theme }) => theme.spacing.small};
  }
`;

export const IconContainer = styled.div`
  transform: rotate(-90deg);

  svg {
    transition: ${({ theme }) => theme.transition};
  }
`;
