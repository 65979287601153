import styled from 'styled-components';

const Container = styled.div`
  border-radius: ${({ theme }) => theme.radius.main};
  box-shadow: ${({ theme }) => theme.shadows.buttonPrimary};
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
`;

export default Container;
