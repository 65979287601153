import styled from 'styled-components';

export const SelectInput = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.large};
  ${({ isDisabled }) =>
    isDisabled
      ? `span { display: none;}`
      : `span { background: linear-gradient(to right, transparent, rgba(235, 237, 245, 1) 69%); transform: translateX(-40px); border-radius: 8px}`}
`;

export const Container = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;

  &:focus-within label {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Icon = styled.div`
  position: absolute;
  transform: rotate(0);
  right: ${({ theme }) => theme.spacing.smallest};
  ${({ openClose }) =>
    openClose ? 'button { transform: rotate(180deg) }' : 'button { transform: rotate(0) }'};

  button {
    width: ${({ theme }) => theme.spacing.largeXL};

    &:disabled {
      svg {
        opacity: 0.3;
      }
    }
  }
`;

export const InputLabel = styled.label`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fonts.weights.heavy};
  font-size: ${({ theme }) => theme.fonts.sizes.primary};
  ${({ isDisabled }) =>
    isDisabled ? `color: lightGray` : `color: ${({ theme }) => theme.newTheme.color.grey.main}`};
`;

export const SelectLabel = styled.label`
  display: none;
`;

export const BackgroundGradient = styled.span`
  width: 75px;
  height: 100%;
  position: absolute;
`;

export const SelectList = styled.select`
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  color: ${({ theme }) => theme.fonts.colors.primary};
  border-radius: ${({ theme }) => theme.radius.main};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fonts.weights.normal};
  font-size: ${({ theme }) => theme.fonts.sizes.primary};
  padding: ${({ theme }) => theme.spacing.medium};
  background-color: ${({ theme }) => theme.colors.inputForm};
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  border: 2px solid transparent;
  margin: 0;

  &:focus {
    color: ${({ theme }) => theme.fonts.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white};
    outline: none;
  }

  &:disabled {
    border: 2px solid ${({ theme }) => theme.newTheme.color.background.disabled} !important;
    color: ${({ theme }) => theme.newTheme.color.disabled} !important;
    background-color: transparent;
  }

  &::-ms-expand {
    display: none;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.smallXL};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.smallXL};
  }
`;

export const SelectOption = styled.option`
  font-size: ${({ theme }) => theme.fonts.sizes.secondary};
  color: ${({ theme }) => theme.fonts.colors.primary};
`;
