import styled from 'styled-components';

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing.longSM};

  label {
    color: ${({ theme }) => theme.fonts.colors.secondary};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    padding: ${({ theme }) => theme.spacing.largest};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.large};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.large};
  }
`;

export const ButtonContainer = styled.div`
  button {
    ${({ theme }) => `padding: ${theme.spacing.smallXL} ${theme.spacing.long}`};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    button {
      display: block;
      width: 100%;
    }
  }
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.error};
`;
