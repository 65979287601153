import React from 'react';
import PropTypes from 'prop-types';

import { ChevronArrow } from 'ui/svg';
import { Action, Container, IconContainer, TextContainer, Title } from './styles';

const CardButton = ({ title, action }) => {
  return (
    <Container>
      <TextContainer>
        <Title>{title}</Title>
        <Action>{action}</Action>
      </TextContainer>
      <IconContainer>
        <ChevronArrow />
      </IconContainer>
    </Container>
  );
};

CardButton.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default CardButton;
