import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  margin: ${({ theme }) => theme.spacing.longest} ${({ theme }) => theme.spacing.longSM};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    margin: ${({ theme }) => theme.spacing.largest};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin: ${({ theme }) => theme.spacing.large};
  }
`;

export const Title = styled.h4`
  font-weight: ${({ theme }) => theme.fonts.weights.normal};
  font-size: ${({ theme }) => theme.fonts.sizes.headings.heading5};
  line-height: ${({ theme }) => theme.lineHeight.secondary};
  color: ${({ theme }) => theme.fonts.colors.primary};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.sizes.headings.heading6};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.fonts.sizes.headings.heading6};

    span:nth-child(2) {
      display: inline;
      margin-left: ${({ theme }) => theme.spacing.smallest};
    }
  }
`;

export const ColoredTitle = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fonts.sizes.headings.heading3};
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.small};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.sizes.headings.heading4};
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.fonts.colors.secondary};
  font-size: ${({ theme }) => theme.fonts.sizes.headings.heading6};
  line-height: ${({ theme }) => theme.lineHeight.secondary};
  margin-top: ${({ theme }) => theme.spacing.smallest};

  span {
    color: ${({ theme }) => theme.fonts.colors.secondary};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.sizes.secondary};
    margin-bottom: ${({ theme }) => theme.spacing.large};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.fonts.sizes.secondary};
  }
`;

export const Span = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weights.normal};
  display: block;
`;
