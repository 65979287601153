import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.long};
  p,
  span {
    color: ${({ theme }) => theme.newTheme.color.grey.main};
  }
  h3 {
    span:nth-child(1) {
      background: ${({ theme }) => theme.newTheme.color.gradient.text};
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      margin-left: 0;
      margin-bottom: ${({ theme }) => theme.spacing.small};
    }
  }
  p:nth-child(2) {
    color: ${({ theme }) => theme.fonts.colors.secondary};
  }
  span:nth-child(2) {
    margin-top: ${({ theme }) => theme.spacing.large};
  }
  span:nth-child(2),
  span:nth-child(3) {
    color: ${({ theme }) => theme.fonts.colors.secondary};
  }
  p span:nth-child(1) {
    color: ${({ theme }) => theme.fonts.colors.secondary};
    @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
      margin-left: ${({ theme }) => theme.spacing.smallest};
    }
    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      margin-left: 0;
    }
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.largest};
    span:nth-child(1) {
      margin-bottom: ${({ theme }) => theme.spacing.large};
      margin-left: ${({ theme }) => theme.spacing.smallest};
    }
    span:nth-child(2),
    span:nth-child(3) {
      display: inline !important;
    }
    span:nth-child(3) {
      margin-left: ${({ theme }) => theme.spacing.smallest};
    }
  }
`;

export const ContainerGrid = styled.div`
  width: 100%;
  padding: 0 !important;
`;

export const Span = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weights.normal};
  display: block;
`;

export const SectionForm = styled.section`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.longest};
  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    padding-bottom: ${({ theme }) => theme.spacing.long};
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    height: auto;
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    ul {
      padding-left: 0;
    }
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    height: auto;
  }
`;

export const List = styled.ul`
  list-style: none;
  width: 100%;
  margin: 0;
  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    li:nth-child(2) {
      margin-top: ${({ theme }) => theme.spacing.largeXL};
    }
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0;
    li:nth-child(2) {
      margin-top: ${({ theme }) => theme.spacing.large};
    }
  }
`;

export const Item = styled.li`
  margin-top: ${({ theme }) => theme.spacing.long};
  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: ${({ theme }) => theme.spacing.large};
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin-top: ${({ theme }) => theme.spacing.large};
  }
`;

export const GradientBackgroud = styled.div`
  width: 100%;
  height: 80%;
  transform: skewY(-5deg);
  background: linear-gradient(256.38deg, #5d73f5 10%, #f46974 100.58%, #f5726a 100.59%);
  position: absolute;
  top: 600px;
  z-index: 0;
  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    height: 100vh;
  }
  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    height: 120vh;
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    height: 135vh;
  }
  @media only screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
    top: 680px !important;
    height: 200vh;
  }
`;
