import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'atomic-design/atoms/button';
import { ChevronArrow } from 'ui/svg';

import {
  SelectInput,
  Icon,
  SelectLabel,
  InputLabel,
  SelectList,
  SelectOption,
  Container,
  BackgroundGradient,
} from './styles';

const Select = ({ options, inputName, name, value, onChange, label, placeHolder, isDisabled }) => {
  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  return (
    <Container>
      <InputLabel htmlFor={inputName} isDisabled={isDisabled}>
        {label}
      </InputLabel>
      <SelectInput onClick={handleClick} isDisabled={isDisabled}>
        <Container>
          <SelectLabel htmlFor={name}>{value}</SelectLabel>
          <SelectList name={name} id={name} value={value} onChange={onChange} disabled={isDisabled}>
            {placeHolder && (
              <option disabled hidden>
                {placeHolder}
              </option>
            )}
            {options.map(option => (
              <SelectOption key={option.id} value={option.text}>
                {option.text}
              </SelectOption>
            ))}
          </SelectList>
        </Container>
        <Icon>
          <BackgroundGradient />
          <Button variant="icon" ariaLabel={show ? 'open' : 'close'} disabled={isDisabled}>
            <ChevronArrow />
          </Button>
        </Icon>
      </SelectInput>
    </Container>
  );
};

Select.propTypes = {
  isDisabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  inputName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string,
};

Select.defaultProps = {
  isDisabled: false,
};

export default Select;
