import React, { useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import options from 'domain/interest-list';

import FormInput from 'atomic-design/organisms/contact-form/form-input';
import FormTextArea from 'atomic-design/organisms/contact-form/form-text-area';
import Select from 'atomic-design/atoms/select';
import Button from 'atomic-design/atoms/button';
import { Form, ButtonContainer, Error } from './styles';

const ContactForm = ({ onSubmit, contactEmail }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const initialState = {
    name: '',
    lastname: '',
    email: '',
    company: '',
    interest: '',
    message: '',
  };

  const [values, setValues] = useState(initialState);

  const { name, lastname, email, company, interest, message } = values;

  const handleChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const resetFields = () => {
    setValues(initialState);
  };

  const getErrorValues = obj => {
    return Object.keys(obj).reduce((a, c) => ((a[`${'error_'}${c}`] = obj[c]), a), {});
  };

  const sendGTMEvent = (err = false) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `contact_${err ? 'error' : 'message'}`,
      ...(err ? getErrorValues(values) : values),
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios({
        method: 'post',
        url: process.env.GATSBY_GETFORM_URL,
        data: new FormData(e.target),
      });
      sendGTMEvent();
      onSubmit();
      resetFields();
    } catch (e) {
      sendGTMEvent(true);
      setError(true);
    }
    setLoading(false);
  };

  return (
    <Form name="contact" onSubmit={handleSubmit}>
      <FormInput
        type="text"
        name="name"
        value={name}
        onChange={handleChange}
        label="First Name"
        required
      />
      <FormInput
        type="text"
        name="lastname"
        value={lastname}
        onChange={handleChange}
        label="Last Name"
        required
      />
      <FormInput
        type="email"
        name="email"
        value={email}
        onChange={handleChange}
        label="E-mail"
        required
      />
      <FormInput
        type="text"
        name="company"
        value={company}
        onChange={handleChange}
        label="Company"
      />
      <Select
        options={options}
        inputName="interest"
        name="interest"
        value={interest}
        onChange={handleChange}
        label="I’m interested in..."
      />
      <FormTextArea
        type="text"
        name="message"
        value={message}
        onChange={handleChange}
        label="Tell us about your project and goals*"
        required
      />
      {error && (
        <Error>
          Error sending message. Please write us to{' '}
          <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
        </Error>
      )}
      <ButtonContainer>
        <Button type="submit" disabled={loading}>
          {loading ? 'Sending...' : 'Send'}
        </Button>
      </ButtonContainer>
    </Form>
  );
};

ContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  contactEmail: PropTypes.string.isRequired,
};

export default ContactForm;
