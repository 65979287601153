import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Link from 'atomic-design/atoms/link';
import { Container, Row, Col } from 'react-grid-system';

import Layout from 'core/layout';
import MetaTags from 'core/meta-tags/index';
import PageHeader from 'atomic-design/atoms/page-header';
import ThankYou from 'atomic-design/atoms/thank-you';
import CardButton from 'atomic-design/atoms/card-button';
import Box from 'atomic-design/atoms/box';
import ContactForm from 'atomic-design/organisms/contact-form';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderLineBreak } from '../common/string-helpers';
import MainContainer from 'atomic-design/atoms/main-container';

import {
  Header,
  SectionForm,
  Content,
  List,
  Item,
  ContainerGrid,
  GradientBackgroud,
} from '../styles/pages/contact-styles';

export const data = graphql`
  query {
    contentfulGeneralSettings {
      childContentfulGeneralSettingsContactPageJsonNode {
        emaildata {
          emails {
            email
          }
        }
      }
    }

    allContentfulPage(filter: { url: { eq: "contact" } }) {
      edges {
        node {
          headerTitle {
            json
          }
          headerSubtitle
          headerDescription {
            headerDescription
          }
          metatagImage {
            file {
              url
            }
          }
          metatagTitle
          metatagDescription
        }
      }
    }
  }
`;

const ContactPage = ({ data, location }) => {
  const [showThankYou, setShowThankYou] = useState(false);

  const contactData =
    data.contentfulGeneralSettings.childContentfulGeneralSettingsContactPageJsonNode;
  const pageHeaderData = data.allContentfulPage.edges[0].node;
  const metatagImage = data.allContentfulPage.edges[0].node.metatagImage.file.url;
  const metatagDescription = data.allContentfulPage.edges[0].node.metatagDescription;
  const metatagTitle = data.allContentfulPage.edges[0].node.metatagTitle;

  return (
    <Layout path={location.pathname}>
      <MetaTags
        title={metatagTitle}
        description={metatagDescription}
        charSet="utf-8"
        image={metatagImage}
        viewport="width=device-width, initial-scale=1"
        type="website"
      />
      <Header>
        <MainContainer>
          <PageHeader
            title={pageHeaderData.headerSubtitle}
            subTitle={documentToReactComponents(pageHeaderData.headerTitle.json)}
            description={renderLineBreak(pageHeaderData.headerDescription.headerDescription)}
          />
        </MainContainer>
      </Header>
      <GradientBackgroud />
      <SectionForm>
        <MainContainer>
          <Container fluid={true} component={ContainerGrid}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Content>
                  <Box>
                    {!showThankYou && (
                      <ContactForm
                        onSubmit={() => setShowThankYou(true)}
                        contactEmail={contactData.emaildata.emails[0].email}
                      />
                    )}
                    {showThankYou && (
                      <ThankYou
                        title="We’ll be in touch soon."
                        coloredTitle="Thank you!"
                        subtitle="Meanwhile, you may want to look around."
                        textButton="See what we’ve been up to"
                        to="/work"
                      />
                    )}
                  </Box>
                </Content>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Content>
                  <List>
                    <Item>
                      <a
                        href={`mailto:${contactData.emaildata.emails[0].email}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <CardButton title="Prefer Email?" action="Drop us a line" />
                      </a>
                    </Item>
                    <Item>
                      <Link to="/careers">
                        <CardButton title="Want to join the team?" action="See our openings" />
                      </Link>
                    </Item>
                  </List>
                </Content>
              </Col>
            </Row>
          </Container>
        </MainContainer>
      </SectionForm>
    </Layout>
  );
};

export default ContactPage;
