import React from 'react';

import SocialNetworkList from 'atomic-design/atoms/social-network-list';
import theme from 'styles/theme';
import iconList from 'domain/socialMediaIcons';

import { Container, Title, ColoredTitle, Subtitle, Span } from './styles';

const args = {
  size: theme.fonts.sizes.icon,
  color: theme.colors.icon.dark,
};

const icons = iconList({ ...args });

const ThankYou = () => {
  return (
    <Container>
      <Title>
        <ColoredTitle>Thank you!</ColoredTitle>
        Keep one eye on your inbox.
        <Span>We’ll be in touch soon.</Span>
      </Title>
      <Subtitle>
        Meanwhile, let’s connect on social?
        <Span>Follow us and see what we’re up to:</Span>
      </Subtitle>
      <SocialNetworkList iconList={icons} />
    </Container>
  );
};

export default ThankYou;
