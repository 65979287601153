import React from 'react';
import PropTypes from 'prop-types';

import Container from './styles';

const Box = ({ children, className }) => (
  <Container>
    <div className={className}>{children}</div>
  </Container>
);

Box.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Box;
